import { Timeline, TimelineItem, TimelineOppositeContent, TimelineSeparator, TimelineConnector, TimelineDot, TimelineContent, timelineOppositeContentClasses } from "@mui/lab";
import { Card, CardContent, CardHeader, Grid, Typography, useMediaQuery } from "@mui/material"
import BasePage from "../../component/BasePage"
import { EVENTS } from "../../constants/schedule";

export const SchedulePage: React.FC = () => {
    const isDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const connectorSX = isDarkMode ? undefined : { bgcolor: 'primary.main' };
    const dotVariant = isDarkMode ? "filled" : "outlined";
    return (
        <BasePage>
            <Grid item xs={12}>
                <Card elevation={1}>
                    <CardHeader title={<Typography variant="h4">Schedule</Typography>}  />
                    <CardContent>
                        <Timeline
                            sx={{
                                [`& .${timelineOppositeContentClasses.root}`]: {
                                    flex: 0.2,
                                },
                                mt: -5,
                                mb: -2,
                            }}
                        >
                            <TimelineItem>
                                <TimelineOppositeContent
                                    sx={{ m: 'auto 0' }}
                                    align="right"
                                    variant="body2"
                                >
                                    Sept 23
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineConnector sx={{ bgcolor: 'transparent' }} />
                                    <TimelineDot variant={dotVariant} color="primary"></TimelineDot>
                                    <TimelineConnector sx={connectorSX} />
                                </TimelineSeparator>
                                <TimelineContent sx={{ py: 2, px: 2, }}>
                                    <Typography variant="h6" component="span">
                                        Wedding Day!
                                    </Typography>
                                </TimelineContent>
                            </TimelineItem>
                            {EVENTS.map(({ name, start, short }) => (
                                <TimelineItem>
                                    <TimelineOppositeContent
                                        sx={{ m: 'auto 0' }}
                                        align="right"
                                        variant="body2"
                                    >
                                        {start}
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineConnector sx={connectorSX} />
                                        <TimelineDot variant={dotVariant} color="primary"></TimelineDot>
                                        <TimelineConnector sx={connectorSX} />
                                    </TimelineSeparator>
                                    <TimelineContent sx={{ py: 1, px: 2, }}>
                                        <Typography variant="h6" component="span">{name}</Typography>
                                        <Typography>{short}</Typography>
                                    </TimelineContent>
                                </TimelineItem>
                            ))}
                            <TimelineItem>
                                <TimelineOppositeContent
                                    sx={{ m: 'auto 0' }}
                                    align="right"
                                    variant="body2"
                                >
                                    10:00 pm
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineConnector sx={connectorSX} />
                                    <TimelineDot variant={dotVariant} color="primary"></TimelineDot>
                                    <TimelineConnector sx={{ bgcolor: 'transparent' }} />
                                </TimelineSeparator>
                                <TimelineContent sx={{ py: 2, px: 2, }}>
                                    <Typography variant="h6" component="span">
                                        Reception Concludes
                                    </Typography>
                                </TimelineContent>
                            </TimelineItem>
                        </Timeline>
                    </CardContent>
                </Card>
            </Grid>
        </BasePage>
    );
}