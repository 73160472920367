type PartyContent = {
    title: string;
    img: string;
    alt: string;
    content: string;
};

export const BRIDAL_PARTY: PartyContent[] = [
    {
        title: "Ashley Rains",
        img: require("../static/images/Ashley.jpg"),
        alt: "Ashley Rains at Pike Place Market",
        content: "Kylee's older sister, Ashley, is a proud boy mom of some of the cutest little kids you will ever meet, Dalton and Tyson.",
    },
    {
        title: 'Kassie McGill',
        img: require("../static/images/Kassie.jpg"),
        alt: "Kassie",
        content: "Kylee's younger Irish twin sister, Kassie, is a proud dog mom of Remy and former college volleyball player.",
    },
    {
        title: "Megan Nolan",
        img: require("../static/images/Megan.jpg"),
        alt: "Megan",
        content: "Kylee's best friend, Megan, loves a little bit of pizza with her parmesan.",
    },
    {
        title: 'Katie Siebenaller',
        img: require("../static/images/Bro.jpg"),
        alt: "Katie and Olive the Cat",
        content: "Nic's older sister, Katie, is a proud cat mom of 3 black cats (ask her about her cats) and inspired the rest of the family to adopt a cat.",
    },
    {
        title: 'Lauren Hunter',
        img: require("../static/images/Lauren.jpg"),
        alt: "Lauren, Kylee, and Kim at Kylee's Law School graduation",
        content: "Kylee's cousin, Lauren, will be a freshman at Long Beach State University this fall.",
    },
];

export const GROOMSMEN: PartyContent[] = [
    {
        title: "Nick Vogl",
        img: require("../static/images/Nick.jpg"),
        alt: "Daddy",
        content: 'Also called "Nick with a K" or "The Other Nick", Nick is an avid basseball fan that has attended 23 (soon to be 25) of the 30 MLB stadiums and is a 2-time slow pitch softball champion.',
    },
    {
        title: 'Ryan Arnold',
        img: require("../static/images/Ryan.jpg"),
        alt: "Ryan looking dapper",
        content: "Ryan owns an aquarium and loves fish, and he also plays bass guitar.",
    },
    {
        title: "Zack Matthews",
        img: require("../static/images/Zack.jpg"),
        alt: "Zack with Ryan's family's dogs on a boat",
        content: "Zack is an avid retro video game enthusiast and easily the best bowler in the group.",
    },
    {
        title: 'Dean Watkins',
        img: require("../static/images/Dean.jpg"),
        alt: "Future Doctor Dean Watkins",
        content: "Dean's favorite food is Chicken Parm and his favorite sport is outdriving Zack Matthews with his 4-wood during golf league on Wednesdays at 4:00pm EST.",
    },
    {
        title: 'AJ Wahlstrom',
        img: require("../static/images/AJ.jpg"),
        alt: "AJ and his wife, Bethany, on their wedding day",
        content: "AJ is a master with the 3D printer and married his partner, Bethany, last summer.",
    },
];