import React from 'react';
import { Box, Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, ListItemButton, } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faBars } from '@fortawesome/free-solid-svg-icons';
import { MenuLinkItem } from '../MenuBar';

type MenuDrawerProps = {
    items: MenuLinkItem[];
};

const MenuDrawer: React.FC<MenuDrawerProps> = ({ items, }) => {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const navigate = useNavigate();
    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    return (
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton aria-label="menu" onClick={handleOpenNavMenu} color="inherit">
                <FontAwesomeIcon icon={faBars} />
            </IconButton>
            <Drawer
                anchor="left"
                keepMounted
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
            >
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: "8px",
                    justifyContent: 'flex-end',
                }}>
                    <IconButton onClick={handleCloseNavMenu}>
                        <FontAwesomeIcon icon={faAngleLeft} />
                    </IconButton>
                </Box>
                <Box role="presentation">
                    <List>
                        {items.map(({ text, icon, link, }) => (
                            <ListItem key={text}>
                                <ListItemButton
                                    onClick={() => {
                                        handleCloseNavMenu();
                                        navigate(link);
                                }}>
                                    <ListItemIcon>
                                        <FontAwesomeIcon icon={icon} />
                                    </ListItemIcon>
                                    <ListItemText primary={text} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Drawer>
        </Box>
    );
};

export default MenuDrawer