export type AccommodationsItem = {
    name: string,
    description: string,
    maps: string,
};

export const HOTELS: AccommodationsItem[] =[
    {
        name: "Homewood Suites by Hilton Seattle Downtown",
        description: "Conveniently located between the ceremony and reception, while also being close to Downtown Seattle.",
        maps: "https://www.hilton.com/en/hotels/seahwhw-homewood-suites-seattle-downtown/",
    },
    {
        name: "EVEN Hotels Seattle Downtown - Lake Union",
        description: "An IHG hotel located just off I-5 and just south of Lake Union.",
        maps: "https://www.ihg.com/evenhotels/hotels/us/en/seattle/seasw/hoteldetail",
    },
    {
        name: "Courtyard Seattle Downtown/Lake Union",
        description: "Marriott hotel also close to Lake Union (and close to Tacos Chukis).",
        maps: "https://www.marriott.com/en-us/hotels/seacd-courtyard-seattle-downtown-lake-union/overview/",
    },
    {
        name: "Renaissance Seattle Hotel",
        description: "Another Marriott hotel in Downtown Seattle, and is the closest on this list to the ceremony location.",
        maps: "https://www.marriott.com/en-us/hotels/SEASM-renaissance-seattle-hotel/overview/",
    },
    {
        name: "Hyatt Place Seattle/Downtown",
        description: "Close to Seattle Center and the Space Needle.",
        maps: "https://www.hyatt.com/en-US/hotel/washington/hyatt-place-seattle-downtown/seazd",
    },
    {
        name: "citizenM Seattle South Lake Union hotel",
        description: "In the South Lake Union neighborhood; in the heart of Amazon's campus.",
        maps: "https://www.citizenm.com/hotels/united-states/seattle/seattle-south-lake-union-hotel",
    },
];

export const NEIGHBORHOODS: AccommodationsItem[] =[
    {
        name: "Ballard",
        description: "This is where the reception is located, and Kylee's favorite neighborhood in Seattle.",
        maps: "https://goo.gl/maps/FDFHg1WGycPr2ocE9",
    },
    {
        name: "Queen Anne",
        description: "One of the first residential neighborhoods north of downtown, home to Kerry Park.",
        maps: "https://goo.gl/maps/eGWHuVXUkiS8ATtu5",
    },
    {
        name: "Fremont",
        description: "The eclectic next door neighbor to Ballard.",
        maps: "https://goo.gl/maps/tCvjptZtz4HBN99S7",
    },
    {
        name: "Magnolia",
        description: "West of Queen Anne, also a residential neighborhood not too far from downtown Seattle.",
        maps: "https://goo.gl/maps/oKRZjaZRu5Ehf1L3A",
    },
];
