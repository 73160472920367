export type FoodAndDrinkItem = {
    name: string,
    description: string,
    maps: string,
};

export const PIZZA_LIST: FoodAndDrinkItem[] = [
    { 
        name: "Dantini's",
        description: "One of our favoites! In between Batch 206 Distillery and Holy Mountain Brewing in Interbay.",
        maps: "https://goo.gl/maps/TM7QLbrxb7BW7YkF9",
    },
    { 
        name: "Rocco's",
        description: "Huge, delicious pizzas, or grab a big slice. Great pizza, but don't forget the root beer.",
        maps: "https://goo.gl/maps/X1q3tGBTGoujnAmH8",
    },
    { 
        name: "Dino's Tomato Pie",
        description: "21+ only, but you can't go wrong with either crust style they offer. Pizza by the slice here too.",
        maps: "https://goo.gl/maps/VyayE8qeHtcCK5SF9",
    },
    { 
        name: "Windy City Pie",
        description: "Best Chicago style deep dish outside of Chicago.",
        maps: "https://g.page/WindyCityPie?share",
    },
    { 
        name: "MOTO",
        description: "Originally known for having MONTHS long wait, this Detroit style pizza shop just recently expanded into the Belltown neighborhood (and uses a pizza robot there).",
        maps: "https://goo.gl/maps/7WsUcABxLow5Lz5r8",
    },
    { 
        name: "Tutta Bella",
        description: "Certified Neopolitan pizzas with a few locations around town.",
        maps: "https://goo.gl/maps/Dn3bV5yJq65ACAkC6",
    },
    { 
        name: "Delancy's",
        description: "Great date night spot tucked into a Ballard neighborhood.",
        maps: "https://goo.gl/maps/gPwWHWHS44VhE3au7",
    },
    { 
        name: "Italian Family Pizza",
        description: "One of the cheapest ways to feed the whole family (outside of Costco) with their gigantic pizzas.",
        maps: "https://goo.gl/maps/D8qAc9Sa2jjY2tbx7",
    },
    { 
        name: "Hot Mama's Pizza",
        description: "Popular pizza by the slice joint.",
        maps: "https://g.page/hotmamaspizza?share",
    },
    { 
        name: "Big Mario's",
        description: "Another good NY style pizza place around town with a few locations.",
        maps: "https://goo.gl/maps/ztoKjJz9LVfu59J48",
    },
    { 
        name: "Via Tribulani",
        description: "Another good neopolitan style pizza.",
        maps: "https://g.page/via-tribunali-seattle?share",
    },
    { 
        name: "Blotto",
        description: "Fantastic little pizza and wine shop.",
        maps: "https://goo.gl/maps/i6KDDQo7AP396RnQ9",
    },
];

export const BREAKFAST_BRUNCH_LIST: FoodAndDrinkItem[] = [
    {
        name: "Glo's",
        description: "One of the best breakfast diners around. Nicholas always gets The Nicholas.",
        maps: "https://goo.gl/maps/Tyb9tEz2eEbQKN3a7"
    },
    {
        name: "Toulouse Petit",
        description: "Rated as one of the best brunch spots around. Get the beignets!",
        maps: "https://g.page/ToulousePetit?share"
    },
    {
        name: "Portage Bay Cafe",
        description: "Great food with a waffle and pancake toppings bar.",
        maps: "https://goo.gl/maps/Z8K9JGSKB851xPRT7"
    },
    {
        name: "Skillet",
        description: "Another good breakfast spot with really good fried chicken breakfast dishes.",
        maps: "https://goo.gl/maps/qsUMVHVy2nvEKGtN9"
    },
    {
        name: "Mecca Cafe",
        description: "Popular breakfast dive in Uptown.",
        maps: "https://g.page/The5PointCafe?share"
    },
    {
        name: "5 Point Cafe",
        description: "Popular breakfast dive by the Space Needle. Same owners as Mecca Cafe.",
        maps: "https://g.page/The5PointCafe?share"
    },
    {
        name: "Salmon Bay Cafe",
        description: "Ballard breakfast spot right by all the boats.",
        maps: "https://g.page/DinerInBallard?share"
    },
];

export const BAKERY_COFFEE_LIST: FoodAndDrinkItem[] = [
    {
        name: "Storyville Coffee",
        description: "Our first date spot, conveniently located at Pike Place Market (also by the \"Original\" Starbucks).",
        maps: "https://goo.gl/maps/q9Mm9JmwEsk3bdBA9"
    },
    {
        name: "Macrina Bakery",
        description: "You cannot go wrong with anything here. Lots of good pasteries.",
        maps: "https://goo.gl/maps/6tnLUyLg5xPRzEZV6"
    },
    {
        name: "Cafe Hagen",
        description: "Scandanvian pasteries and coffee. Great shareable brunch board on the weekends too.",
        maps: "https://goo.gl/maps/VDkp9K8yJB7fhJa96"
    },
    {
        name: "La Parisienne French Bakery",
        description: "Great French bakery and home of our favorite baguette.",
        maps: "https://goo.gl/maps/RGsMQqtxdD61gP9AA"
    },
    {
        name: "Caffe Vita",
        description: "Local coffee chain with some of the best coffee around. Check out their location at a local radio station, KEXP.",
        maps: "https://goo.gl/maps/XuCK7uiE4bN2QLyq8"
    },
    {
        name: "Victrola Coffee Roasters",
        description: "Another local coffee shop with a few locations.",
        maps: "https://goo.gl/maps/hXiKxZAS48wa6c3F9"
    },
    {
        name: "Nielsen's Pasteries",
        description: "Tasty Danish pasteries in Lower Queen Anne.",
        maps: "https://goo.gl/maps/SLd84KN5BkiWpjBG6"
    },
    {
        name: "Rubinstein Bagels",
        description: "Bagels right across from the Amazon Spheres.",
        maps: "https://goo.gl/maps/SuZ4Z3Eu2BwtPHVN8"
    },
    {
        name: "Rachel's Bagels & Burritos",
        description: "Bagels & Burritos.",
        maps: "https://goo.gl/maps/ATNVTvzWgxVRQxSs5"
    },
    {
        name: "General Porpoise",
        description: "Amazing filled donuts.",
        maps: "https://g.page/general-porpoise-capitol-hill?share"
    },
    {
        name: "Top Pot",
        description: "Local donut chain with a good variety.",
        maps: "https://goo.gl/maps/hLV4c1AkQ6JsUBcj8"
    },
    {
        name: "Starbucks Reserve",
        description: "If you have to go to Starbucks, go to the Reserves at their HQ in SoDo (easy free parking), or the larger one in Capitol Hill",
        maps: "https://goo.gl/maps/E64EKMtZUXfL7B4S7"
    },
];

export const CHEAP_EATS_LIST: FoodAndDrinkItem[] = [
    {
        name: "Tacos Chukis",
        description: "The best tacos in Seattle hands down.",
        maps: "https://goo.gl/maps/y45iWyXQTxmzDrWDA"
    },
    {
        name: "Dick's Drive-In",
        description: "The iconic local burger chain with a simple menu: burgers, fries, and shakes. \"Dick's is the place where the cool hang out.\" - Sir Mix-a-lot",
        maps: "https://goo.gl/maps/AdwZ3YxBpmZuHWsP7"
    },
    {
        name: "Piroshky Piroshky",
        description: "Famous Seattle Russian bakery serving fresh piroshky at Pike Place Market.",
        maps: "https://goo.gl/maps/9T7dDUiw1tSSsRyv9"
    },
    {
        name: "Pike Place Chowder",
        description: "Ranked as one of the best places in America for Clam Chowder. Go to the Pacific Place location to avoid huge lines.",
        maps: "https://g.page/pikeplacechowder-pine?share"
    },
    {
        name: "Niko's Gyros",
        description: "Local Greek shop with good cheap gyros and fries.",
        maps: "https://goo.gl/maps/P4gvJdPzqsAksXcx9"
    },
    {
        name: "Due' Cucina",
        description: "Craving pasta? This restaurant offers fresh pasta fast for a reasonable cost.",
        maps: "https://g.page/due-cucina-capitol-hill?share"
    },
    {
        name: "Carmelo's",
        description: "Another fast Mexican joint with good tacos and burritos, convenient lunch for Kylee across from Seattle University.",
        maps: "https://goo.gl/maps/1cQv9D872xmU9o2U8"
    },
    {
        name: "Costco",
        description: "It's technically local to Seattle. For those Costco lovers, you can visit Store #1 (next to the original location it outgrew) down in the SoDo neighborhood.",
        maps: "https://goo.gl/maps/M21dV7Wuu7aYXuox8"
    },
];