import React from "react";
import { Box, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { MenuLinkItem } from "../MenuBar";

type MenuLinkBarProps = {
    items: MenuLinkItem[];
};

const MenuLinkBar: React.FC<MenuLinkBarProps> = ({ items }) => {
    return (
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {items.map(({ text, link, }) => (
                <Button
                    key={text}
                    variant='text'
                    color='inherit'
                    component={Link}
                    to={link}
                    sx={{ my: 2, display: 'flex' }}
                >
                    {text}
                </Button>
            ))}
        </Box>
    )
};

export default MenuLinkBar;