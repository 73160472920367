import React from 'react';
import { Card, CardContent, Typography, Grid, CardHeader, List, ListItem, ListItemText, ListItemIcon, IconButton } from '@mui/material';
import { faAngleRight, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BasePage from '../../component/BasePage';
import { ATTRACTIONS, BARS, ThingsToDoItem } from '../../constants/thingstodo';

type ThingCard = {
    title: string,
    content: string,
    list: ThingsToDoItem[],
}

const CONTENT: ThingCard[] = [
    {
        title: "Things to See",
        content: "There are many reasons why over 21 million people visit Settle every year, and here are a few of our recommendations for while you are visiting.",
        list: ATTRACTIONS,
    },
    {
        title: "Bars and Breweries",
        content: "Seattle is home to many craft breweries (that are actually good) and unique bars.",
        list: BARS,
    },
];

export const ThingsToDoPage: React.FC = () => {
    return (
        <BasePage>
            {CONTENT.map(({ title, content, list }, contentIndex) => (
                <Grid item xs={12} key={contentIndex}>
                    <Card elevation={1}>
                        <CardHeader title={<Typography variant="h5">{title}</Typography>} />
                        <CardContent>
                            <Typography>
                                {content}
                            </Typography>
                            <List dense={true}>
                                {list.map(({ name, description, maps, }, index) => (
                                    <ListItem key={`${contentIndex}-${index}`}
                                        secondaryAction={
                                            <a href={maps} target="_blank" rel="noreferrer">
                                                <IconButton edge="end" aria-label="delete">
                                                    <FontAwesomeIcon icon={faLocationDot} />
                                                </IconButton>
                                            </a>
                                        }
                                    >
                                        <ListItemIcon>
                                            <FontAwesomeIcon icon={faAngleRight} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={name}
                                            secondary={description}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </BasePage>
        
    );
};