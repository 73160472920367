import React from 'react';
import { Card, CardMedia, CardContent, Typography, Grid, Divider, Link, CardHeader } from '@mui/material';
import BasePage from '../../component/BasePage';

export const WeddingPage: React.FC = () => {
    return (
        <BasePage>
            <Grid item xs={12} width='100%'>
                <Card elevation={1}>
                    <CardMedia
                        component="img"
                        alt="Kylee and Nicholas' proposal"
                        height='375'
                        image={require("../../static/images/Proposal-With-Ring.jpg")}
                    />
                    <CardContent>
                        <Typography variant="h3" align="center">
                            Seattle, Washington
                        </Typography>
                        <Divider variant="middle" sx={{ margin: 1 }} />
                        <Typography variant="h3" align="center">
                            September 23, 2023
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} width='100%'>
                <Card elevation={1}>
                    <CardHeader title={<Typography variant="h4">Kylee and Nic Through the Years</Typography>} />
                    <CardMedia
                        component="iframe"
                        sx={{ height: { md: 480 }, minHeight: 240}}
                        src="https://www.youtube.com/embed/EXdfJ07bIx4?si=hV6Lbtt7z7cNhSuY"
                    />
                    <CardContent>                       
                        <Typography>Video created by Ashley Rains</Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card elevation={1}>
                    <CardContent>
                        <Typography variant="h5" align="center">
                            <Link href="https://vimeo.com/event/3623559" target="_blank" rel="noreferrer" underline="hover" color="inherit">
                                Watch the Livestream at 2:30 PM PT, 5:30 PM ET
                            </Link>
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </BasePage>
    );
};