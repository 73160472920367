import { InferType, array, boolean, number, object, string } from "yup";

// Update validator on backend too

const personSchema = object({
    name: string().label("Full name").required(),
    isComing: boolean().label("Are attending").default(false),
    isPlusOne: boolean().default(false),
    meal: string().label("Meal").when('isComing', {
        is: true,
        then: (schema) => schema.oneOf(["salmon", "chicken", "veggie", "kids"], "Please select a meal option for this person").required(),
        otherwise: (schema) => schema.nullable(),
    }),
    restrictions: string().nullable(),
});

export const formSchema = object({
    people: array(personSchema).label("Last name").min(1).required(),
    maxPeople: number().required(),
    email: string().label("Email").nullable().email(),
    songs: string().label("Song requests").nullable(),
});

export type FormData = InferType<typeof formSchema>;
export type Person = InferType<typeof personSchema>;