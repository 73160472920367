import React from 'react';
import { Card, CardMedia, CardContent, Typography, Grid, CardHeader, } from '@mui/material';
import BasePage from '../../component/BasePage';
import { ABOUT_US_LIST } from '../../constants/aboutus';

export const AboutUsPage: React.FC = () => {
    return (
        <BasePage>
            {ABOUT_US_LIST.map(({ title, img, alt, content, author, }, index) => (
                <Grid item xs={12} key={index}>
                    <Card elevation={1}>
                        <CardHeader title={<Typography variant="h4">{title}</Typography>} />
                        <CardMedia
                            component="img"
                            alt={alt}
                            height='300'
                            image={img}
                        />
                        <CardContent>                       
                            <Typography>{content}</Typography>
                            <Typography align="right" sx={{ marginTop: 2 }}>Written By {author}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </BasePage>
    );
};