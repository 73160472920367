import { Link } from "@mui/material";
import { ReactNode } from "react";

type FaqItem = {
    q: string,
    a: string | ReactNode,
};

export const FAQ_LIST: FaqItem[] = [
    {
        q: "Where in Seattle is everything happening?",
        a: "The ceremony will be at St. James Cathedral in First Hill (804 9th Ave, Seattle, WA, 98104), and the reception at Ray's Boathouse Northwest Room in Ballard (6049 Seaview Ave NW, Seattle, WA, 98107).",
    },
    {
        q: "What is the recommended mode of transportation for the wedding day?",
        a: "As long as you have a designated driver, we recommend driving as it will be more convenient. Taxis, Ubers, and Lyfts are all readily available for those that do not want to drive or do not have a car.",
    },
    {
        q: "Will there be free parking available?",
        a: "Yes! For the ceremony, please park in the O'Dea High School parking lot (802 Terry Ave, Seattle, WA, 98104) across the street from the Cathedral. For the reception, Ray's has a parking lot that you are welcome to park in.",
    },
    {
        q: "When do we need to get to the Cathedral for the ceremony?",
        a: "Try to arrive by 2:00 pm, 30 minutes before the ceremony, but no later than 2:15 pm.",
    },
    {
        q: "Can I take pictures during the ceremony?",
        a: "No, we ask that you put your phones away during our ceremony. We hired photographers (husband and wife team, Michelle & Evan Photography) to take photos and will make them available once we have them. The Cathedral explicitly prohibits people taking photos as it disrupts the matrimony and the importance the ceremony has on both the bride and the groom as well as other guests.",
    },
    {
        q: "When can we get pictures with the bride and groom?",
        a: "We have given the photogrphers a list of pictures to take at the Cathedral, where we will primarily be focused on taking pictures with immediate family and the wedding party. We hope to be able to take pictures with more of our extended family and other guests at the reception.",
    },
    {
        q: "What are the wedding colors?",
        a: "Colors will be navy blue, dusty blue, and white. Nic might try to add a red accent color somewhere because he thinks it will make our wedding colors more like the Seattle Kraken's colors.",
    },
    {
        q: "What is the dress code?",
        a: "Cocktail attire. The ceremony is in a Catholic Church, so please be respectful with your clothing choices.",
    },
    {
        q: "What will the weather be like?",
        a: "Expect moderate, summer like weather, although there may be a chance of light rain. The average September temperature is 67 and the reception location is both indoor and outdoor right on the Puget Sound. As of September 18, it looks like it will be partly cloudy with highs in the mid 60s.",
    },
    {
        q: "Who made your wedding cake?",
        a: "Morfey's in Belltown! They also make a batch of cupcakes in the morning you can buy (the Crème brûlée cupcake is amazing).",
    },
    {
        q: "Did you realize that you scheduled a wedding on the same day as the Ohio State vs. Notre Dame game?",
        a: "Hopefully it will be a night game, so it will be after the wedding ceremony. That being said, you cannot watch the game during the ceremony if it is on.",
    },
    {
        q: "Do we have to root for Ohio State on that day?",
        a: "No, but you cannot root for Michigan. Ever (according to Nic). You can root for Pac-12 teams, they definitely need the support. Michigan State is also acceptable since they don't like Michigan too.",
    },
    {
        q: "Is there a livestream link for the wedding?",
        a: <>
            The livestream will begin at 2:30 PM PT, 5:30 PM ET and can be accessed through this Vimeo link:&nbsp;
            <Link href="https://vimeo.com/event/3623559" target="_blank" rel="noreferrer" color="inherit">
                https://vimeo.com/event/3623559
            </Link>
        </>,
    },
    {
        q: "What should we do if we can no longer attend the wedding or reception?",
        a: "Please contact Kylee or Nicholas directly if there are any changes to your party's attendance.",
    },
];