type AboutUsContent = {
    title: string;
    img: string;
    alt: string;
    content: string;
    author: string;
};

export const ABOUT_US_LIST: AboutUsContent[] = [
    {
        title: "Kylee McGill",
        img: require("../static/images/Kylee-1.jpg"),
        alt: "Picture of Kylee McGill",
        content: "Kylee Brianna McGill is the most wonderful person you'll ever meet (and hella cute). "
            + "Born to Katie and Kevin McGill back in 1998, sister to Ashley and eventual Irish twin sister to Kassie, "
            + "this legend got her start growing up between Sacramento and Lake Tahoe in Northern California. "
            + "Kylee not only has the personality and the looks, but the smarts too! The MVP of her Mock Trial team in high school, "
            + "she decided to continue her education by getting a college degree in Psychology. Kylee, an avid college football fan, "
            + "had to go to a school with a football team, and ended up at Arizona State University. However, a Bachelor's degree was not the end of her education; "
            + "law school is. Kylee wanted to go to a school in a place where she felt like she could live and work in. This is what brought her up to Seattle. " 
            + "Graduating in May 2023, Kylee will be graduating from Seattle University School of Law with a J. D. "
            + "Outside of her busy life out of law school, Kylee loves to do things with Nicholas like putting together puzzles, "
            + "going out on date nights, trying to get to all 50 states together, hit every continent, etc. She has become an Ohio State fan since dating Nicholas. "
            + "Kylee has also adopted all the Seattle sports teams, especially the Seattle Kraken.  Most importantly, she loves her Australian Shepherd, Colt (Nicholas is now a dog dad). "
            + "Nicholas has never met a woman who made him smile and laugh so much, who wanted to be with him so much, and who loved him so much.",
        author: "Nicholas",
    },
    {
        title: 'Nicholas "Nic" Siebenaller',
        img: require("../static/images/Nic-1.jpg"),
        alt: "Picture of Nic",
        content: "Nicholas was born and raised in Oregon, Ohio: a suburb of Toledo. He is the youngest child and has an older sister, Katie, whom he loves to compete with. "
            + "Nicholas grew up an avid fan of The Ohio State Buckeyes. He started watching their football games in 2002. "
            + "His mother was worried that he wouldnt understand Ohio State wouldn't always be a good team and would eventually lose. "
            + "However, 20 years later, they continue to win and she is happy about that. "
            + "Nicholas went to The Ohio State University and graduated in 2019 with a Bachelors in computer science and a minor in political science. "
            + "He works as a software developer at Amazon, now working on Alexa. Nicholas enjoys his job even though it is stressful at times. "
            + "He moved to Seattle following graduation and got to experience life on the West Coast. It was the first time he had seen real mountains and was in shock at the lack of bugs and humidity. " 
            + "Nicholas has a black cat named Phillip. He is a [cute] menace to society and enjoys biting people. Just kidding, he's pretty cool. He does bite though. "
            + "Anyone who knows Nicholas, knows that he likes more things than he dislikes. His likes include hockey, football, learning, and his family. "
            + "Nicholas also loves free things. Wherever we go, if there is something free, he will grab it. We have a lot of knick knacks because of it which is nice. "
            + "Nicholas also loves cooking. He loves trying new recipes and is always asking his mother and father for family recipes. "
            + "He makes homemade pasta and pizza weekly, expressing his Italian roots. Lastly, Nicholas deeply cares about his friends. "
            + "He talks to them often and always talks about how much he misses them. They speak on the phone frequently about life and future trips to see each other. "
            + "Having to describe Nicholas is a few paragraphs is hard. How do you put your entire world into a short collection of words? He is truly the most kind person I have ever met. "
            + "He deeply cares about everyone that is in his life and will do anything for anyone. He wishes no ill will on anyone and is happy just to be living life. "
            + "He loves to dance with me and sing with me. He is my partner in life in all the senses of the word; my soulmate; my everything. ",
        author: "Kylee",
    },
    {
        title: "How We Met",
        img: require("../static/images/First-Pic.jpg"),
        alt: "First picture of Kylee and Nic together",
        content: "Back on a cool Sunday morning in February at the Ballard Farmer's Market, "
            + "I (Nicholas) was doing my usual route at the market looking for some fresh food for the week. "
            + "Suddenly, a man called out to me, \"Are you from Ohio?\" Being a proud Ohioan wearing my Script Ohio hoodie, "
            + "I walked over and told the merchant where I am from and that I went to Ohio State. "
            + "This beautiful woman, also at this booth looking at pickles, looked at me and immediately made a comment to the effect of "
            + "\"Ohio State is the worst.\" Being excited that someone actually recognized Ohio State, I was like, \"Well at least we win.\" "
            + "We then had a conversation about college football and who we route for. " 
            + "This woman told me about how her dad used to play for Arizona State, and I thought that was so cool. "
            + "Before we went our separate ways, I made my move, asking for her number, promising a date if she was interested. And that is how I met Kylee.",
        author: "Nicholas",
    },
    {
        title: 'Our Engagement',
        img: require("../static/images/After-Yes.jpg"),
        alt: "Kylee and Nic after she said \"Yes!\"",
        content: "March 20th, 2022: We had gotten a late start to the day because we flew home from Chicago in the early hours of the morning. "
            + "It was our 1 year anniversary and Nicholas had booked us reservations at the Loupe Lounge at the Space Needle. It was pretty chilly out and I wasnt sure what to wear because we were walking to the Needle. "
            + "Nicholas said he wanted to go early to check out the view and I thought nothing of it, silly me. We got there about 15 minutes before our reservation and went outside to see the view. "
            + "Nicholas told me to put my purse down which I did because I thought he was going to have someone take our picture. But he didn't. He just kept hugging me and saying he was afraid of heights. "
            + "I thought that was odd behavior, but he really is afraid of heights. Next thing I know he dropped to one knee and asked me to marry him. At the moment, I was so surprised I forgot to say yes! "
            + "Fortunately, Nicholas had secretly hired a photographer to document the event. After the proposal, we had a relaxing dinner before telling our families the exciting news.",
        author: "Kylee",
    },
];