import React from 'react';
import { Card, CardMedia, CardContent, Typography, Grid, CardHeader, Stack, } from '@mui/material';
import BasePage from '../../component/BasePage';
import { BRIDAL_PARTY, GROOMSMEN } from '../../constants/party';

export const PartyPage: React.FC = () => {
    return (
        <BasePage direction='row' maxWidth='lg' alignItems="flex-start">
            <Grid item xs={12}>
                <Card elevation={1}>
                            <CardHeader title={<Typography variant="h4">Wedding Party</Typography>} />
                            <CardContent>                       
                                <Typography>Get to know the wedding party through a fun fact about them.</Typography>
                            </CardContent>
                        </Card>
            </Grid>
            <Grid item xs={12} md={6}>
                <Stack spacing={2}>
                    {BRIDAL_PARTY.map(({ title, img, alt, content, }, index) => (
                        <Card elevation={1} key={index}>
                            <CardHeader title={<Typography variant="h4">{title}</Typography>} />
                            <CardMedia
                                component="img"
                                alt={alt}
                                image={img}
                            />
                            <CardContent>                       
                                <Typography>{content}</Typography>
                            </CardContent>
                        </Card>
                    ))}
                </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
                <Stack spacing={2}>
                    {GROOMSMEN.map(({ title, img, alt, content, }, index) => (
                        <Card elevation={1} key={index}>
                            <CardHeader title={<Typography variant="h4">{title}</Typography>} />
                            <CardMedia
                                component="img"
                                alt={alt}
                                image={img}
                            />
                            <CardContent>                       
                                <Typography>{content}</Typography>
                            </CardContent>
                        </Card>
                    ))}
                </Stack>
            </Grid>
        </BasePage>
    );
};