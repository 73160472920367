import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { Card, CardHeader, Typography, CardContent, CardActions, Box, Stack, Alert, } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { InferType, object, string } from "yup";
import { FormInputText } from "../../component/FormComponents/FormInputText";
import { getInvitee } from "../../client/siebenaller/getInvitee";
import { FormData } from "../../schema";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const gateSchema = object({
    name: string().label("Last Name").required(),
    code: string().label("Code").min(4).max(4).required(),
});

export type GateData = InferType<typeof gateSchema>;

type RsvpGatePageProps = {
    setData: (formData: FormData) => void,
}

export const RsvpGatePage: React.FC<RsvpGatePageProps> = ({
    setData,
}: RsvpGatePageProps) => {
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [searchParams] = useSearchParams();
    const { control, handleSubmit, formState: { isValid, isSubmitting } } = useForm<GateData>({
        resolver: yupResolver(gateSchema),
        mode: "onTouched",
        defaultValues: {
            name: "",
            code: "",
        },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onSubmit = async (data: GateData) => 
        getInvitee(data.name, data.code)
            .then((response) => {
                setData(response.data as unknown as FormData);
                setErrorMessage(undefined);
            })
            .catch(error => setErrorMessage(`Error ${error.response.status}: ${error.response.data.error}`));
    useEffect(() => {
        if (searchParams.has("name") && searchParams.has("code")) {
            onSubmit({ name: searchParams.get("name")!!, code: searchParams.get("code")!! });
        }
    }, [searchParams, onSubmit]);
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Card elevation={1}>
                <CardHeader title={<Typography variant="h4" sx={{ letterSpacing: 5 }}>RSVP</Typography>} />
                <CardContent>
                    <Box sx={{ width: '100%' }}>
                        <Typography variant="body1">Enter in the last name and 4 digit code found in your wedding invitation to access your RSVP page. Please submit your response by July 31, 2023. Nic built this site and RSVP system, so let Nic know if you run into any issues or bugs so he can fix them.</Typography>
                        <Stack
                            spacing={2}
                            sx={{ mt: 2 }}
                            direction={{ xs: 'column', md: 'row' }}
                            justifyContent="flex-start"
                        >
                            <FormInputText
                                control={control}
                                name={`name`}
                                label="Last Name"
                                variant="outlined"
                                fullWidth
                            />
                            <FormInputText
                                control={control}
                                name={`code`}
                                label="Four Digit Code"
                                variant="outlined"
                                fullWidth
                            />
                        </Stack>
                    </Box>
                </CardContent>
                <CardActions>
                    <Stack
                        spacing={2}
                        direction={{ xs: 'column-reverse', md: 'row' }}
                        justifyContent="flex-start"
                    >
                        <LoadingButton
                            variant="contained"
                            type="submit"
                            loading={isSubmitting}
                            disabled={!isValid}
                        >
                            Submit
                        </LoadingButton>
                        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                    </Stack>
                </CardActions>
            </Card>
        </form>
    );
}