import BasePage from "../../component/BasePage"
import { FormData } from "../../schema";
import { Box, Card, CardContent, CardHeader, Grid, Typography, } from "@mui/material";
import { RsvpFormPage } from "./rsvpFormPage";
import { RsvpGatePage } from "./rsvpGatePage";
import { useState } from "react";

export const RsvpPage: React.FC = () => {
    const [values, setValues] = useState<FormData>();
    return (
        <BasePage>
            <Grid item xs={12} sx={{ width: '100%' }}>
                {
                    values ? (
                        <RsvpFormPage data={values} />
                    ) : (
                        <RsvpGatePage setData={setValues} />
                    )
                }
            </Grid>
        </BasePage>
    );
}

export const PastRsvpPage: React.FC = () => {
    return (
        <BasePage>
            <Grid item xs={12} sx={{ width: '100%' }}>
                <Card elevation={1}>
                    <CardHeader title={<Typography variant="h4">RSVP has Closed</Typography>} />
                    <CardContent>
                        <Box sx={{ width: '100%' }}>
                            <Typography variant="body1">We are no longer accepting RSVPs, and all who have not given a response will assumed to not be coming. If you did RSVP, but you will not be able to make it to the wedding, please contact Kylee or Nicholas directly.</Typography>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
        </BasePage>
    );
}