import React from 'react';
import { Route, Routes, Navigate } from "react-router-dom";
import type {} from '@mui/lab/themeAugmentation';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { WeddingPage, FoodAndDrinksPage, FaqPage, AboutUsPage, ThingsToDoPage, RegistryPage, SchedulePage, AccommodationsPage, PartyPage, PastRsvpPage } from '../page';
import "./App.css";

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
          primary: {
            main: '#000047',
            contrastText: '#ffffff',
          },
          secondary: {
            main: '#1a4c39',
            contrastText: '#ffffff',
          },
          background: prefersDarkMode ? {
            paper: '#202a44',
            default: '#333e5c',
          } : {
            paper: '#adc7d9',
            default: '#e4f2ff',
          },
        },
        typography: {
          fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            'Quicksand',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            'Ephesis',
            'Pinyon Script',
            'cursive',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(','),
          h3: {
            fontFamily: "Ephesis",
          },
          h4: {
            fontFamily: "Ephesis",
          },
          body1: {
            fontFamily: "Quicksand",
          },
        },
      }),
    [prefersDarkMode],
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path="wedding" element={<WeddingPage />} />
        <Route path="wedding/about-us" element={<AboutUsPage />} />
        <Route path="wedding/schedule" element={<SchedulePage />} />
        <Route path="wedding/food" element={<FoodAndDrinksPage />} />
        <Route path="wedding/things-to-do" element={<ThingsToDoPage />} />
        <Route path="wedding/registry" element={<RegistryPage />} />
        <Route path="wedding/faq" element={<FaqPage />} />
        <Route path="wedding/rsvp" element={<PastRsvpPage />} />
        <Route path="wedding/accommodations" element={<AccommodationsPage />} />
        <Route path="wedding/party" element={<PartyPage />} />
        <Route path="*" element={<Navigate to="wedding" replace />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
