type RegistrySite = {
    title: string;
    link: string;
    description: string;
    image: string;
}

export const REGISTRY: RegistrySite[] = [
    {
        link: 'https://www.venmo.com/nic7aller',
        image: require("../static/images/House.jpg"),
        title: 'Home Fund',
        description: 'As with any young couple without a trust fund, we are saving for our future home. If you want to get a gift, but are overwhelmed by the options below, consider this option. Clicking here will take you to Venmo, but we also have Zelle if you prefer.',
    },
    {
        link: 'https://www.crateandbarrel.com/gift-registry/kylee-mcgill-and-nicholas-siebenaller/r6578617',
        image: require("../static/images/CB.jpg"),
        title: 'Crate & Barrel',
        description: 'Crate & Barrel offers a wide selection of home goods and also offers a nice event for those just starting their wedding registry.',
    },
    {
        link: 'https://www.williams-sonoma.com/registry/zd2k69nppn/registry-list.html',
        image: require("../static/images/W-S.jpg"),
        title: 'Williams Sonoma',
        description: 'Williams Sonoma has all things kitchen, and shares a website registry system with Pottery Barn below.',
    },
    {
        link: 'https://www.potterybarn.com/registry/zd2k69nppn/registry-list.html',
        image: require("../static/images/P-B.jpeg"),
        title: 'Pottery Barn',
        description: 'Pottery Barn, another home goods store, offers more additions to the home outside of its sister company Williams Sonoma.',
    },
]; 