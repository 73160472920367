import React from 'react';
import { Typography, Grid, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BasePage from '../../component/BasePage';
import { FAQ_LIST } from '../../constants/faq';

export const FaqPage: React.FC = () => {
    return (
        <BasePage>
            <Grid item xs={12}>
                {FAQ_LIST.map(({ q, a, }, index) => (
                    <Accordion elevation={1} defaultExpanded key={index}>
                        <AccordionSummary
                            expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
                            aria-controls={`panel${index}-content`}
                            id={`panel${index}-header`}
                        >
                            <Typography variant='h5' marginRight={2}>{q}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{a}</Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Grid>
        </BasePage>
    );
};