import React from 'react';
import { Card, CardMedia, CardContent, Typography, Grid, CardActionArea, CardHeader } from '@mui/material';
import BasePage from '../../component/BasePage';
import { REGISTRY } from '../../constants/registry';

export const RegistryPage: React.FC = () => {
    return (
        <BasePage>
            <Grid item xs={12}>
                <Card elevation={1}>
                    <CardHeader title={<Typography variant="h4">Our Registries</Typography>} />
                    <CardContent>                       
                        <Typography>Below is a list of all the places we have registered at for gifts. You can just click on the card and you will be directed to that companies website.</Typography>
                    </CardContent>
                </Card>
            </Grid>
            {REGISTRY.map(({ image, title, description, link, }, index) => (
                <Grid item xs={12} key={index} width='100%'>
                    <Card elevation={1}>
                        <CardActionArea href={link}>
                            <CardHeader title={<Typography variant="h5">{title}</Typography>} />
                            <CardMedia
                                component="img"
                                alt={title}
                                height='300'
                                image={image}
                            />
                            <CardContent>                       
                                <Typography>{description}</Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            ))}
        </BasePage>
    );
};