import React from 'react';
import { Card, CardContent, Typography, Grid, CardHeader, List, ListItem, ListItemText, ListItemIcon, IconButton } from '@mui/material';
import { faAngleRight, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BasePage from '../../component/BasePage';
import { AccommodationsItem, HOTELS, NEIGHBORHOODS } from '../../constants/accomodations';

type AccommodactionsCard = {
    title: string,
    content: string,
    list: AccommodationsItem[],
}

const CONTENT: AccommodactionsCard[] = [
    {
        title: "Hotels",
        content: "Seattle has many hotels, but still being in the summer cruise ship season, prices may not come down to their offseason prices by the wedding dates. The following are some suggestions for hotels based on price and location. Feel free to reach out to Nic if you have any questions.",
        list: HOTELS,
    },
    {
        title: "Airbnb/VRBO",
        content: "For those looking to book an Airbnb or VRBO, here is a list of recommended neighborhoods to be close to both the reception and ceremony.",
        list: NEIGHBORHOODS,
    },
];

export const AccommodationsPage: React.FC = () => {
    return (
        <BasePage>
            {CONTENT.map(({ title, content, list }, contentIndex) => (
                <Grid item xs={12} key={contentIndex}>
                    <Card elevation={1}>
                        <CardHeader title={<Typography variant="h5">{title}</Typography>} />
                        <CardContent>
                            <Typography>
                                {content}
                            </Typography>
                            <List dense={true}>
                                {list.map(({ name, description, maps, }, index) => (
                                    <ListItem key={`${contentIndex}-${index}`}
                                        secondaryAction={
                                            <a href={maps} target="_blank" rel="noreferrer">
                                                <IconButton edge="end" aria-label="delete">
                                                    <FontAwesomeIcon icon={faLocationDot} />
                                                </IconButton>
                                            </a>
                                        }
                                    >
                                        <ListItemIcon>
                                            <FontAwesomeIcon icon={faAngleRight} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={name}
                                            secondary={description}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </BasePage>
        
    );
};