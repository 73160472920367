import React from 'react';
import { faUtensils, faCircleQuestion, faHeart, faBinoculars, faGift, faCalendarDay, faHotel, faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import { Breakpoint, Container, Grid } from '@mui/material';
import MenuBar from '../../component/MenuBar';

const items = [
    {
        text: "About Us",
        icon: faHeart,
        link: "/wedding/about-us",
    },
    {
        text: "Seattle Food",
        icon: faUtensils,
        link: "/wedding/food",
    },
    {
        text: "Things To Do",
        icon: faBinoculars,
        link: "/wedding/things-to-do",
    },
    {
        text: "Accommodations",
        icon: faHotel,
        link: "/wedding/accommodations",
    },
    {
        text: "Registry",
        icon: faGift,
        link: "/wedding/registry",
    },
    {
        text: "Schedule",
        icon: faCalendarDay,
        link: "/wedding/schedule",
    },
    {
        text: "Wedding Party",
        icon: faPeopleGroup,
        link: "/wedding/party",
    },
    {
        text: "FAQ",
        icon: faCircleQuestion,
        link: "/wedding/faq",
    },
];

type BasePageProps = {
    direction?: 'row' | 'column';
    maxWidth?: Breakpoint;
    alignItems?: 'flex-start' | 'center';
    justifyContent?: 'flex-start' | 'center';
};

const BasePage: React.FC<BasePageProps> = ({ children, direction, maxWidth, justifyContent, alignItems }) => {

    return (
        <Container maxWidth={maxWidth || 'md'} sx={{ mb: { xs: 1, sm: 2 } }}>
            <MenuBar items={items} />
            <Grid container
                direction={direction || 'column'}
                justifyContent={justifyContent || "center"}
                alignItems={alignItems || "center"}
                alignSelf={'center'}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                spacing={2}
            >
                {children}
            </Grid>
        </Container>
    );
};

export default BasePage;