import React from 'react';
import { Card, CardContent, Typography, Grid, CardHeader, List, ListItem, ListItemText, ListItemIcon, IconButton } from '@mui/material';
import { faAngleRight, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BasePage from '../../component/BasePage';
import { BAKERY_COFFEE_LIST, BREAKFAST_BRUNCH_LIST, CHEAP_EATS_LIST, FoodAndDrinkItem, PIZZA_LIST } from '../../constants/foodanddrinks';

type FoodCard = {
    title: string,
    content: string,
    list: FoodAndDrinkItem[],
}

const CONTENT: FoodCard[] = [
    {
        title: "Pizza",
        content: "Seattle has a fast growing pizza scene, and with (Nic) being an avid pizza fan, here is a list of some good pizza places.",
        list: PIZZA_LIST,
    },
    {
        title: "Breakfast & Coffee",
        content: "Skip Starbucks and hit up a local spot to enjoy breakfast at. Here are some good coffee shop and quick breakfast places.",
        list: BAKERY_COFFEE_LIST,
    },
    {
        title: "Brunch",
        content: "If you want more than pasteries and coffee, hit up one of these excellent brunch spots. Reservations may be required.",
        list: BREAKFAST_BRUNCH_LIST,
    },
    {
        title: "Cheap and Fast Eats",
        content: "Skip the national fast food chains and eat local! Here are some options for getting a quick bite to eat outside of breakfast.",
        list: CHEAP_EATS_LIST,
    },
];

export const FoodAndDrinksPage: React.FC = () => {
    return (
        <BasePage>
            {CONTENT.map(({ title, content, list }, contentIndex) => (
                <Grid item xs={12} key={contentIndex}>
                    <Card elevation={1}>
                        <CardHeader title={<Typography variant="h5">{title}</Typography>} />
                        <CardContent>
                            <Typography>
                                {content}
                            </Typography>
                            <List dense={true}>
                                {list.map(({ name, description, maps, }, index) => (
                                    <ListItem key={`${contentIndex}-${index}`}
                                        secondaryAction={
                                            <a href={maps} target="_blank" rel="noreferrer">
                                                <IconButton edge="end" aria-label="delete">
                                                    <FontAwesomeIcon icon={faLocationDot} />
                                                </IconButton>
                                            </a>
                                        }
                                    >
                                        <ListItemIcon>
                                            <FontAwesomeIcon icon={faAngleRight} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={name}
                                            secondary={description}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </BasePage>
        
    );
};