export type ThingsToDoItem = {
    name: string,
    description: string,
    maps: string,
};

export const ATTRACTIONS: ThingsToDoItem[] =[
    {
        name: "Seattle Center",
        description: "Created for the World's Fair in 1962, Seattle Center is home to many iconic attractions including the Space Needle, MoPOP, Chihuly Garden and Glass, and Pacific Science Center.",
        maps: "https://goo.gl/maps/yY3WXeDSkgbw9K3VA",
    },
    {
        name: "Pike Place Market",
        description: "The iconic Seattle market home to a bunch of local vendors, the \"First\" Starbucks, fish throwing, Gum Wall, and great views.",
        maps: "https://g.page/pikeplacepublicmarket?share",
    },
    {
        name: "Kerry Park",
        description: "Get your postcard views of Seattle here. Get some ice cream a few blocks away from Molly Moon's too!",
        maps: "https://goo.gl/maps/E3K2YTiRhdXaLr259",
    },
    {
        name: "Seattle Waterfront",
        description: "Take the steps down from Pike Place to the waterfront home to Seattle Aquarium, restaurants and more.",
        maps: "https://g.page/Aquarium-Seattle?share",
    },
    {
        name: "Ballard Locks",
        description: "Do you like watching boats rise and lower between 2 bodies of water of significantly different heights? Then this is the place for you.",
        maps: "https://goo.gl/maps/ppQXbrEAJCMbi8rA9",
    },
    {
        name: "University of Washington Campus",
        description: "Walk around one of the most picturesque college campuses around.",
        maps: "https://goo.gl/maps/6hVqNaJviPssQZLG6",
    },
    {
        name: "Golden Gardens Park",
        description: "Public park and beach by Shishole Marina where you might be able to catch some sea lions.",
        maps: "https://goo.gl/maps/MCCh7cKM7fyw564A9",
    },
    {
        name: "Fremont Troll",
        description: "One of the most iconic statues from the 90s, the Fremont Troll is here to fulfill all of your nostalgia.",
        maps: "https://goo.gl/maps/8nu6JoC38F1MjvNTA",
    },
    {
        name: "Seattle Mariners",
        description: "Check out Seattle's MLB team at T-Mobile Park as they take on the Houston Astros September 25-27.",
        maps: "https://goo.gl/maps/JSkTXfgRpvBTb2M39",
    },
    {
        name: "Seattle Kraken",
        description: "See the new Climate Pledge Arena and catch the Kraken at the beginning of their 3rd season in a preseason NHL game September 25.",
        maps: "https://goo.gl/maps/WqpvWbzfCthgcTCV7",
    },
    {
        name: "National Nordic Museum",
        description: "Feel like a Viking learning about the history of Scandanavian emigrants to America and their story of settling around the country.",
        maps: "https://g.page/NordicMuseum?share",
    },
    {
        name: "Alki Beach",
        description: "The largest Seattle beach with lots of sand and restaurants, including some of the best fish and chips in Seattle.",
        maps: "https://goo.gl/maps/idZJayd4cmS9xsuZA",
    },
    {
        name: "Kurt Cobain's House",
        description: "Grunge fans can visit the home of the late Kurt Cobain to pay respects to the legend.",
        maps: "https://goo.gl/maps/uHeT3tHM77KMZNMc6",
    },
];

export const BARS: ThingsToDoItem[] =[
    {
        name: "Rhein Haus",
        description: "German bar with 1 liter beers, giant soft pretzels, and bocce.",
        maps: "https://goo.gl/maps/XKXVGLc2ZkCo7mDAA",
    },
    {
        name: "Tapster",
        description: "Be your own bartender at this pour your own drink bar.",
        maps: "https://goo.gl/maps/uoh65MRqLWMmcgbz8",
    },
    {
        name: "Trade Winds Tavern",
        description: "Local bar with cocktails and beer on draft.",
        maps: "https://goo.gl/maps/AdEMp8jZumgn1HNMA",
    },
    {
        name: "Ballard Brewries and Bars",
        description: "Ballard is home to over 10 breweries like Reuben's and Stoup, and even more bars like Percy's.",
        maps: "https://goo.gl/maps/WuySkmdD1LUXzdrh6",
    },
    {
        name: "Inside Passage",
        description: "Unique bar-within-a-bar with creative themed drinks.",
        maps: "https://goo.gl/maps/yWkjbJfysS67CRvi6",
    },
    {
        name: "Le Caviste Wine Bar",
        description: "The owners here get all their wine directly from France and they have some of the best charcuterie boards around.",
        maps: "https://goo.gl/maps/TA9aDJjcmsmZKX9z9",
    },
    {
        name: "T.S. McHugh's",
        description: "Irish pub in Uptown by Kylee and Nic.",
        maps: "https://goo.gl/maps/197awtkj2DvSvvNm9",
    },
    {
        name: "Queen Anne Beer Hall",
        description: "Large hall with many options on tap, also serving beer in liters and good giant pretzels.",
        maps: "https://goo.gl/maps/b32EEj3oFPcVzEiaA",
    },
    {
        name: "Buckley's",
        description: "Local sports bar home to many alumni watch parties (but not for The Ohio State University).",
        maps: "https://goo.gl/maps/TAz77JUzVoATscry9",
    },
];
