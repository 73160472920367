import { faBed, faChurch, faFootball, faMountainCity, faRoute, IconDefinition } from "@fortawesome/free-solid-svg-icons";

export type ScheduleEvent = {
    name: string;
    start: string;
    icon: IconDefinition;
    short: string;
    description?: string;
}

export const EVENTS: ScheduleEvent[] = [
    {
        name: "Wake Up",
        start: "8:30 am",
        icon: faBed,
        short: "On the West Coast, college football starts early. Plan accordingly!",
        description: "",
    },
    {
        name: "Noon Kickoff",
        start: "9:00 am",
        icon: faFootball,
        short: "Noon games back east are starting. Make sure to grab some local coffee/mimosas!",
        description: "",
    },
    {
        name: "Explore Seattle",
        start: "9:00 am",
        icon: faMountainCity,
        short: "For those wanting to checkout Seattle, I recommend getting started early. Pike Place Market is packed before 10 am.",
        description: "",
    },
    {
        name: "Arrive at the Ceremony",
        start: "2:00 pm",
        icon: faRoute,
        short: "Try to get to St. James Cathedral around 15-30 minutes before the ceremony starts!",
        description: "",
    },
    {
        name: "Wedding Ceremony",
        start: "2:30 pm",
        icon: faChurch,
        short: "The main event! Nic finally gets to see Kylee in her wedding dress.",
        description: "",
    },
    {
        name: "Travel to the Reception",
        start: "4:00 pm",
        icon: faRoute,
        short: "Once the ceremony is over (well before this time), there will be plenty of time for everyone to get to the reception.",
        description: "",
    },
    {
        name: "Reception Begins",
        start: "5:00 pm",
        icon: faRoute,
        short: "The reception at Ray's Northwest Room will open for everyone at 5 pm. Enjoy a cocktail hour with tray-passed appetizers!",
        description: "",
    },
    {
        name: "Dinner is Served",
        start: "6:00 pm",
        icon: faRoute,
        short: "The plated meal you chose will be served to you along with a salad and dinner rolls.",
        description: "",
    },
    {
        name: "Last Call",
        start: "9:30 pm",
        icon: faRoute,
        short: "Last call for drinks, but the music will still be going.",
        description: "",
    },
]