import React from "react";
import { AppBar, Slide, Toolbar, Typography, useScrollTrigger, styled, useTheme, useMediaQuery } from "@mui/material";
import MenuDrawer from "../MenuDrawer";
import MenuLinkBar from "../MenuLinkBar";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type MenuBarProps = {
    items: MenuLinkItem[];
};

export type MenuLinkItem = {
    text: string;
    icon: IconProp;
    link: string;
};

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

const MenuBar: React.FC<MenuBarProps> = ({ items }) => {
    const theme = useTheme();
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 10,
    });
    const slideTrigger = useScrollTrigger();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const shouldSlideIn = (isDesktop || !slideTrigger);
    const elevation = trigger ? 2 : 0;
    const color = trigger ? 'primary' : 'transparent';
    return (
        <>
            <Slide appear={false} direction="down" in={shouldSlideIn}>
                <AppBar elevation={elevation} color={color} enableColorOnDark>
                    <Toolbar>
                        <MenuDrawer items={items}/>
                        <Typography 
                            variant="h3" 
                            noWrap
                            component='a'
                            href='/wedding'
                            sx={{
                                mr: 2,
                                display: 'flex',
                                flexGrow: { xs: 1, md: 0},
                                mb: 1,
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                                Kylee & Nicholas
                        </Typography>
                        <MenuLinkBar items={items} />
                    </Toolbar>
                </AppBar>
            </Slide>
            <Offset sx={{ margin: 1, }} />
        </>
    )
};

export default MenuBar;